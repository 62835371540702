import React from 'react';

import { colours } from '@src/components/styled/variables';

import { Section } from '@src/components/styled/section';
import { Layout, SEO } from '../../layout';
import { Link } from '../../components';
import Assets from './components/assets';
import Boilerplates from './components/boilerplates';

import KeyStats from './components/key-stats';

const Press: React.FC = () => (
  <Layout>
    <SEO title="Press" />
    <Section className="inner">
      <h1>Press</h1>
      <p>
        Welcome to the Fidel API press resources page. Our latest brand assets,
        including Fidel API logos, product images, key stats, executive team
        bios and more are available below.
      </p>
      <p>
        For additional media inquiries, please reach out to{' '}
        <Link to="mailto:press@fidelapi.com" target="_blank">
          press@fidelapi.com
        </Link>
        .
      </p>
      <Boilerplates />
    </Section>

    <Section bgColor={colours.lightGrey2} centered maxWidth={1248}>
      <KeyStats />
      <Assets />
    </Section>
  </Layout>
);

export default Press;
