import React from 'react';

import styled from 'styled-components';
import { FeatureCTA, Section } from '../../../components/styled';

import FidelBlackLogo from '../../../static/images/fidel/logo-full-black.svg';
import FidelWhiteLogo from '../../../static/images/fidel/logo-full-white.svg';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: left;
`;

const Assets: React.FC = () => (
  <Section className="wide press-assets">
    <Container>
      <div>
        <h2 className="text-left smaller-h2">Brand assets</h2>
        <InnerContainer>
          <p>
            {`To download Fidel's brand access, navigate to the Figma link
              below.`}
          </p>
          <FeatureCTA
            id="download-brand-assets"
            style={{ width: '250px' }}
            as="a"
            href="https://www.figma.com/design/dtfetTlFtkUbwCrpzgi9vL/Fidel-logos?m=auto&t=N0kr1BQdCSMqq9tl-6"
            rel="noopener noreferrer"
            target="_blank"
          >
            Download brand assets
          </FeatureCTA>
        </InnerContainer>
      </div>

      <div className="logo-box-container">
        <div className="logo-box">
          <FidelBlackLogo />
        </div>
        <div className="logo-black-box">
          <FidelWhiteLogo />
        </div>
      </div>
    </Container>
  </Section>
);

export default Assets;
